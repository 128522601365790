import { Grid, useMediaQuery } from "@mui/material";
import "../styles/Magazine.css";
import "../index.css";
import { useParams } from "react-router-dom";
import { ArticleCard } from "./ArticleCard";
import {
  BACKEND_URL,
  MAGAZINE,
  formatDescription,
  EDITORIAL_CATEGORY,
} from "../constants";
import { useEffect, useState } from "react";
import { getMethod } from "../crud";

export const Magazine = () => {
  // TODO this will not be used when backend is on. It will be replaced
  // with a useEffect or something to get the id
  const params = useParams();
  const [magazine, setMagazine] = useState({
    title: "",
    articles: [],
  });
  const [editorial, setEditorial] = useState({
    title: "",
    content: "",
  });

  const [articlesWithoutEditorial, setArticlesWithoutEditorial] = useState([]);

  const isMobile = useMediaQuery("(max-width: 1024px)");

  useEffect(() => {
    const result = getMethod(BACKEND_URL + MAGAZINE + "/" + params.id);
    result
      .then((response) => {
        setMagazine(response);
        const editorialTmp = response.articles.filter(
          (article) => article.category === EDITORIAL_CATEGORY
        )[0];
        setEditorial(
          editorialTmp !== undefined
            ? editorialTmp
            : {
                title: "",
                content: "",
              }
        );
        setArticlesWithoutEditorial(
          response.articles.filter(
            (article) => article.category !== EDITORIAL_CATEGORY
          )
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2 className="magazine-title">
          {magazine.title}
          {/* Show text if there is a special edition and if its a smaller resolution, put it on a new line */}
          {magazine.specialEdition === undefined ? (
            ""
          ) : isMobile ? (
            <>
              <br />
              <span
                style={{
                  color: "#bccdce",
                  fontStyle: "italic",
                  fontSize: "1rem",
                }}
              >
                {" " + magazine.specialEdition}
              </span>
            </>
          ) : (
            <span style={{ color: "#bccdce", fontStyle: "italic" }}>
              {" " + magazine.specialEdition}
            </span>
          )}
        </h2>
      </Grid>
      <Grid item xs={12} sx={{ marginTop: "10px" }}>
        <Grid container spacing={1}>
          {editorial.title !== "" && (
            <Grid item xs={12} className="debug-class1">
              <ArticleCard
                category={editorial.category}
                description={formatDescription(editorial.description, 200)}
                title={editorial.title}
                id={editorial.id}
              />
            </Grid>
          )}
          {articlesWithoutEditorial.length > 0 &&
            articlesWithoutEditorial.slice(0, 2).map((article, i) => (
              <Grid key={i} item xs={12} lg={6} className="debug-class">
                <ArticleCard
                  category={article.category}
                  description={formatDescription(article.description, 200)}
                  title={article.title}
                  id={article.id}
                  splitted={1}
                />
              </Grid>
            ))}
          {articlesWithoutEditorial.length > 0 &&
            articlesWithoutEditorial
              .slice(2, articlesWithoutEditorial.length)
              .map((article, i) => (
                <Grid key={i} item xs={12} className="debug-class1">
                  <ArticleCard
                    category={article.category}
                    description={formatDescription(article.description, 200)}
                    title={article.title}
                    id={article.id}
                  />
                </Grid>
              ))}
        </Grid>
      </Grid>
    </Grid>
  );
};
