import { Card, CardContent, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/ArticleCard.css";

export const ArticleCard = ({
  category,
  title,
  description,
  id,
  splitted = false,
  showCategory = true,
}) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  return (
    <Card
      variant="outlined"
      sx={!isMobile && splitted && { minHeight: "17em" }}
    >
      <CardContent>
        <Typography color="text.secondary">
          {showCategory && (
            <Link
              className="link"
              to={"/articles/" + encodeURIComponent(category.toLowerCase())}
            >
              {category}
            </Link>
          )}
        </Typography>
        <Typography component="div" className="article-title">
          <Link to={"/article/" + id} className="title-link">
            {" "}
            {title}{" "}
          </Link>
        </Typography>
        <Typography
          sx={{
            fontSize: "0.95rem",
            marginTop: "10px",
            marginBottom: "10px",
            fontFamily: "serif",
          }}
        >
          {description}
        </Typography>
      </CardContent>
    </Card>
  );
};
