import { Grid, Typography } from "@mui/material";

export const About = () => {
  const aboutUs =
    "Suntem o echipă de studenți de la Facultatea de Automatică și Calculatoare (CTI) din cadrul Universității POLITEHNICA București. Scriem pentru și despre calculatoriștii curioși, care vor să privească și în afara cercului specializării lor. Credem în experimentare, în puterea jocurilor de cuvinte și în umorul non-bășcălios. Efortul nostru este îndreptat către construirea unei culturi a facultății, către crearea sentimentului de apartenență la un grup de oameni care știu ce-i cu Assembly-ul, dar care mai văd și lumina soarelui.";

  return (
    <Grid container style={{ padding: "15px" }}>
      <Grid item xs={12} lg={10}>
        <Typography
          style={{
            fontFamily: "IBM BIOS",
            fontSize: "2rem",
            marginBottom: "20px",
          }}
        >
          Despre noi
        </Typography>
        <Typography
          sx={{
            fontFamily: "serif",
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
          }}
        >
          {aboutUs}
          <br />
          <br />
          Echipa este formată din:
          <br />
          <br />
          Mihai Lățea - redactor, dezvoltator software
          <br />
          <br />
          Ana Mîrza - redactor
          <br />
          <br />
          Iulia Dumitru - redactor
          <br />
          <br />
          Maria Sfîrăială - redactor
          <br />
          <br />
          Andrei Duma - mentor
          <br />
          <br />
        </Typography>
        <Typography
          style={{
            fontFamily: "IBM BIOS",
            fontSize: "1.5rem",
            marginBottom: "20px",
          }}
        >
          Mulțumiri
        </Typography>
        <Typography
          sx={{
            fontFamily: "serif",
            fontSize: "1.2rem",
            lineHeight: "1.5rem",
          }}
        >
          Îi suntem recunoscători lui Andrei Duma
          <sup>
            <a
              id="footnote1-ref"
              style={{ fontSize: "1.22rem" }}
              href="#footnote1"
            >
              1
            </a>
          </sup>{" "}
          pentru că a împărtășit cu noi ideea lui. Fără el, nici revista, nici
          numele ei n-ar fi existat. Îi mulțumim pentru îndrumarea permanentă pe
          toate planurile - tehnic, administrativ, literar, relațional, estetic
          și multe altele. <br />
          <br />
          <a style={{ fontSize: "1.22rem" }} href="https://narativa.space/">
            Irinei Pietroc
          </a>{" "}
          îi suntem recunoscători pentru că ne-a înțeles perspectiva atât de
          bine încât a reușit să surprindă toate dorințele noastre într-un logo.
          <br />
          <br />
          <hr />
          <Typography
            sx={{
              fontFamily: "serif",
              fontSize: "0.9rem",
              lineHeight: "1.5rem",
            }}
          >
            1. Fost student la Calculatoare, actual prieten, îndrumător și
            sfătuitor al echipei de redacție.
            <a id="footnote1" href="#footnote1-ref">
              &#8617;
            </a>
          </Typography>
        </Typography>
      </Grid>
    </Grid>
  );
};
