import {
  Button,
  Grid,
  TextField,
  ThemeProvider,
  Typography,
  createTheme,
} from "@mui/material";
import "../styles/Subscribe.css";
import { useState } from "react";
import { postMethod } from "../crud";
import { BACKEND_URL, LOCAL_HOST_URL, SUBSCRIBE } from "../constants";

export const Subscribe = () => {
  // Override text field style for underline animation color change
  const customTheme = createTheme({
    components: {
      MuiTextField: {
        styleOverrides: {
          root: {
            "& .MuiInput-root": {
              
              fontFamily: "CORDATA",
              "&::after": {
                borderBottom: "2px solid #af7d5e",
              },
              "&.Mui-focused::after": {
                borderBottom: "2px solid #af7d5e",
              },
            },
          },
        },
      },
    },
  });

  const [email, setEmail] = useState("");
  const [error, setError] = useState(false);
  const [message, setMessage] = useState("");

  const isValidEmail = (email) => {
    return /\S+@\S+\.\S+/.test(email);
  }

  const subscribeEmail = () => {
    if (!isValidEmail(email) || email === "") {
      setError(true);
      setMessage("Te rog să introduci o adresă de email validă");
    } else {

      const payload = email;
      setMessage("Vei primi un email de confirmare în scurt timp.");

      postMethod(BACKEND_URL + SUBSCRIBE, payload).then((response) => {
        setError(false);
      }).catch((error) => {
        setError(true);
        setMessage(error);
      });
    }
  }

  return (
    <ThemeProvider theme={customTheme}>
      <Grid container style={{ padding: "15px" }}>
        <Grid item xs={12} lg={10}>
          <Typography
            style={{
              fontFamily: "IBM BIOS",
              fontSize: "1rem",
              marginBottom: "20px",
            }}
          >
            Abonează-te la newsletter
          </Typography>
          <TextField
            className="email-field"
            variant="standard"
            placeholder="Adresa de email"
            onChange={(e) => setEmail(e.target.value)}
          ></TextField>
          <button className="subscribe-button" onClick={subscribeEmail}>
            Trimite
          </button>
          <p
            className="email-message"
            style={{
              color: error ? "red" : "green",
            }}
          >
            {message}
          </p>
        </Grid>
      </Grid>
    </ThemeProvider>
  );
};
