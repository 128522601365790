import { Outlet } from "react-router-dom";
import { Header } from "./component/Header";
import { Grid } from "@mui/material";
import "./index.css";
import "./styles/Root.css";
import CookieConsent from "react-cookie-consent";
import { cremComunist } from "./constants";

export const Root = () => {
  return (
    <div>
      <CookieConsent
        buttonText={"Am înțeles"}
        buttonStyle={{ backgroundColor: cremComunist, color: "#433403" }}
      >
        Acest site folosește cookie-uri pentru a vă îmbunătăți experiența.
        Continuând să navigați pe acest site, sunteți de acord cu utilizarea
        cookie-urilor conform Politicii de utilizare a cookie-urilor.
      </CookieConsent>
      <Header />
      <Grid container alignItems={"center"} justifyContent={"center"}>
        <Grid item xs={12} md={8} lg={7} className="debug-class">
          <Grid container sx={{ marginTop: "5%" }}>
            <Outlet />
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
