import { Grid, Typography, useMediaQuery } from "@mui/material";
import { Link } from "react-router-dom";
import "../styles/MagazineNumbers.css";
import { formatDescription } from "../constants";

export const MagazineNumbers = ({ year, numbers }) => {
  const isMobile = useMediaQuery("(max-width: 1024px)");

  // coworkers are mean and they don't like the description :(
  const isDescription = false;

  const descriptionLen = 200;

  return isMobile ? (
    <Grid container sx={{ padding: "15px" }}>
      <Grid item xs={12}>
        <h2 className="year"> {year} </h2>
      </Grid>
      <Grid item xs={12}>
        {numbers.map((number, i) => (
          <Link key={i} to={"/magazine/" + number.id} className="magazine-link">
            <p>
              {number.title}
              <br />
              {isDescription && (
                <Typography className={"magazine-description"}>
                  {formatDescription(number.description, descriptionLen)}
                </Typography>
              )}
            </p>
          </Link>
        ))}
      </Grid>
    </Grid>
  ) : (
    <Grid container justifyItems={"center"} spacing={5}>
      <Grid item xs={4}>
        <h2 className="year"> {year} </h2>
      </Grid>
      <Grid item xs={4}>
        {numbers.length > 0 &&
          numbers.map((number, i) => (
            <Link
              key={i}
              to={"/magazine/" + number.id}
              className="magazine-link"
            >
              <p>
                {number.title}
                <br />
                {isDescription && (
                  <Typography className={"magazine-description"}>
                    {formatDescription(number.description, descriptionLen)}
                  </Typography>
                )}
              </p>
            </Link>
          ))}
      </Grid>
    </Grid>
  );
};
