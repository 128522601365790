export const maroComunist = "#af7d5e";
export const cremComunist = "#f9dea8";
export const rozComunist = "#995b50";
export const GITHUB_CODE_GRAY_DARK = "#343942";
export const GITHUB_CODE_GRAY_LIGHT = "#eff1f3";

export const magazineName = "calculatoristul";

export const LOCAL_HOST_URL = "http://localhost:8080";
export const BACKEND_URL = "https://api.calculatoristul.ro";

export const SEASONS = "/seasons";
export const MAGAZINE = "/magazine";
export const ARTICLE = "/article";
export const ARTICLES = "/articles"
export const SUBSCRIBE = "/abonare";
export const UNSUBSCRIBE = "/dezabonare";
export const CONFIRM_SUBSCRIBE = "/confirmare";

export const AUTHORS = "/authors";
export const MAGAZINES = "/magazines";
export const CATEGORIES = "/categories";


export const EDITORIAL_CATEGORY = "EDITORIAL";
export const REDACTIA_CALCULATORISTUL = "Redacția";

// format description to be a certain length and add ellipsis
export const formatDescription = (description, len = 20) => {
  if (description === undefined || description === null) {
    return "";
  }
  if (description.length > len) {
    return description.slice(0, len) + "...";
  }
  return description;
};
