
import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import reportWebVitals from "./reportWebVitals";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { ErrorPage } from "./component/ErrorPage";
import { Root } from "./Root";
import { Archive } from "./component/Archive";
import { Magazine } from "./component/Magazine";
import { Article } from "./component/Article";
import { About } from "./component/About";
import { Subscribe } from "./component/Subscribe";
import { Unsubscribe } from "./component/Unsubscribe";
import { ConfirmSubscription } from "./component/ConfirmSubscription";
import { CategoryArticles } from "./component/CategoryArticles";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Archive />,
      },
      {
        path: "/magazine/:id",
        element: <Magazine />,
      },
      {
        path: "/article/:id",
        element: <Article />,
      },
      {
        path: "/about",
        element: <About />,
      },
      {
        path: "/subscribe",
        element: <Subscribe />,
      },
      {
        path: "/unsubscribe",
        element: <Unsubscribe />,
      },
      {
        path: "/confirm-subscription",
        element: <ConfirmSubscription />,
      },
      {
        path: "/articles/:category",
        element: <CategoryArticles />,
      }
    ],
    errorElement: <ErrorPage />,
  },
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
