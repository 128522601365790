import { Grid, Typography } from "@mui/material";
import { Link, useParams } from "react-router-dom";
import "../styles/Article.css";
import Markdown from "react-markdown";
import remarkGfm from "remark-gfm";
import rehypeRaw from "rehype-raw";
import { useEffect, useState } from "react";
import { getMethod } from "../crud";
import { ARTICLE, ARTICLES, BACKEND_URL, GITHUB_CODE_GRAY_LIGHT, REDACTIA_CALCULATORISTUL } from "../constants";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { oneDark } from "react-syntax-highlighter/dist/esm/styles/prism";

// Add custom component in markdown component for code blocks using react-syntax-highlighter
// Based on the language, there are different color palettes
// If the language is not found (eg. ``` code ```) block, apply custom styling
// Note: Thanks CHAT_GPT (I'm a crappy developer)
const components = {
  code({ node, inline, className, children, ...props }) {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <SyntaxHighlighter
        children={String(children).replace(/\n$/, "")}
        style={oneDark}
        customStyle={{ fontSize: ".9rem" }}
        language={match[1]}
        PreTag="div"
        {...props}
      />
    ) : (
      <code
        className={className}
        {...props}
        style={{ backgroundColor: GITHUB_CODE_GRAY_LIGHT, borderRadius: "5px" }}
      >
        {children}
      </code>
    );
  },
};

export const Article = () => {

  const params = useParams();

  const [article, setArticle] = useState({
    title: "",
    content: "",
    category: "",
    writeDate: "",
    author: "",
    authorDepartment: "",
  });

  useEffect(() => {
    const result = getMethod(BACKEND_URL + ARTICLE + "/" + params.id);

    result
      .then((response) => {
        setArticle(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography
          sx={{ fontSize: ".75rem", marginLeft: "10px" }}
          color="text.secondary"
          gutterBottom
        >
          <Link
            className="link"
            to={
              ARTICLES +
              "/" +
              encodeURIComponent(article.category.toLowerCase())
            }
          >
            {article.category}
          </Link>
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <h2 className="magazine-title"> {article.title} </h2>
      </Grid>
      <Grid item xs={12}>
        <Typography
          sx={{
            fontSize: ".7rem",
            marginTop: "10px",
            marginLeft: "10px",
            color: "#995b50",
            fontFamily: "IBM BIOS",
          }}
          gutterBottom
        >
          {article.author === REDACTIA_CALCULATORISTUL
            ? article.author + " "
            : "De " + article.author + " "}
          <span
            style={{
              fontFamily: "serif",
              color: "#995b50",
              fontSize: ".9rem",
              fontWeight: "bold",
            }}
          >
            |
          </span>{" "}
          {article.authorDepartment}
        </Typography>
        <Typography
          sx={{ fontSize: ".6rem", marginLeft: "10px", fontFamily: "IBM BIOS" }}
          color="text.secondary"
          gutterBottom
        >
          {new Date(article.writeDate).toLocaleDateString("ro-ro", {
            year: "numeric",
            month: "long",
            day: "numeric",
          })}
        </Typography>
      </Grid>
      <Grid
        item
        xs={12}
        sx={{
          padding: "15px",
          fontFamily: "serif",
          fontSize: "1.2rem",
        }}
      >
        {/* sr-only class is a hack to hide ugly footnotes title */}
        <Markdown
          remarkPlugins={[remarkGfm]}
          rehypePlugins={[rehypeRaw]}
          components={components}
        >
          {article.content}
        </Markdown>
      </Grid>
    </Grid>
  );
};
