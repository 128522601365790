import { useEffect, useState } from "react";
import { getMethod } from "../crud";
import { ARTICLES, BACKEND_URL, formatDescription } from "../constants";
import "../styles/CategoryArticles.css"
import { useParams } from "react-router-dom";
import { Grid } from "@mui/material";
import { ArticleCard } from "./ArticleCard";

// NOTE: the path variable for {category} is in lower case
// The API works with upper case category name
export const CategoryArticles = () => {
  const [articles, setArticles] = useState([]);

  const params = useParams();
  const [category, setCategory] = useState(params.category.slice(0, 1).toUpperCase() + params.category.slice(1));

  useEffect(() => {
    const result = getMethod(BACKEND_URL + ARTICLES + "/" + category.toUpperCase());
    result
      .then((response) => {
        setArticles(response);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  return (
    <Grid container>
      <Grid item xs={12}>
        <h2 className="category-title">
          {category}          
        </h2>
      </Grid>
	  <Grid item xs={12} sx={{ marginTop: "10px" }}>
        <Grid container spacing={1}>
          {articles
              .map((article, i) => (
                <Grid key={i} item xs={12}>
                  <ArticleCard
                    category={article.category}
                    description={formatDescription(article.description, 200)}
                    title={article.title}
                    id={article.id}
					showCategory={false}
                  />
                </Grid>
              ))}
        </Grid>
	  </Grid>
	  </Grid>
  );
};
